import React from 'react';
import ReactDOM, {hydrate, render} from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const AppJSX = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
const rootElement = document.getElementById('root')
if(rootElement.hasChildNodes()) {
  hydrate(AppJSX, rootElement)
} else {
  render(AppJSX, rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
