import React from "react";
import ContactFooter from '../../components/footer/contactfooter'
const Services = (props) => {
  
  return (
    <>
     
    <ContactFooter />
    </>
  );
};

export default Services;
